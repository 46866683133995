import {
	aisMvHandleRegistrationConstants
} from './constants'
import {
	userConstants
} from "../../../store/constants/user.constants"
import {
	appealsConstants
} from '../../Appeals/store/constants'
import { i18n } from './localization'
import isRu from '../../../helpers/isRu'

const initialState =
{
	isSending: false,
	isSendingChange: false,
	currentItem: [],
	subscriberCreate: null,
	subscriberDelete: null,
	error: null,
	aisMvBySearch: [],
	aisMvBySearchIsSending:false,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case userConstants.Logout.SUCCESS:
			return {
				...state,
				isSending: false,
				currentItem: [],
				aisMvBySearch: []
			}
		case appealsConstants.SET_FILTERS: {
			return {
				...state,
				currentItemOld: state.currentItem,
				aisMvBySearchOld: state.aisMvBySearch
			}
		}
		case aisMvHandleRegistrationConstants.getAisMvExistSubscribers.REQUEST:
			return {
				...state,
				currentItem: null,
				isSending: true,
			}
		case aisMvHandleRegistrationConstants.getAisMvExistSubscribers.SUCCESS:
			return {
				...state,
				isSending: false,
				currentItem: action.payload.result
			}
		case aisMvHandleRegistrationConstants.getAisMvExistSubscribers.FAILURE:
			return {
				...state,
				isSending: false,
				error: action.payload
			}
		case aisMvHandleRegistrationConstants.getAisMvBySearch.REQUEST:
			return {
				...state,
				aisMvBySearch: null,
				aisMvBySearchIsSending: true,
			}
		case aisMvHandleRegistrationConstants.getAisMvBySearch.SUCCESS:
			return {
				...state,
				aisMvBySearchIsSending: false,
				aisMvBySearch: action.payload.result
			}
		case aisMvHandleRegistrationConstants.getAisMvBySearch.FAILURE:
			return {
				...state,
				aisMvBySearchIsSending: false,
				error: action.payload
			}
		case aisMvHandleRegistrationConstants.createSubscribeAisMv.REQUEST:
			return {
				...state,
				isSendingChange: true,
				subscriberCreate: null,
			}
		case aisMvHandleRegistrationConstants.deleteSubscribeAisMv.REQUEST:
			return {
				...state,
				isSendingChange: true,
				subscriberDelete: null,
			}
		case aisMvHandleRegistrationConstants.createSubscribeAisMv.FAILURE:
			return {
				...state,
				isSendingChange: false,
				subscriberCreate: null,
				error: action.payload
			}
		case aisMvHandleRegistrationConstants.deleteSubscribeAisMv.FAILURE:
			return {
				...state,
				isSendingChange: false,
				subscriberDelete: null,
				error: action.payload
			}
		case aisMvHandleRegistrationConstants.createSubscribeAisMv.SUCCESS:
			return {
				...state,
				isSendingChange: false,
				subscriberCreate: action.payload.result ? i18n.messageItemAddSuccses[isRu()] : null,
			}
		case aisMvHandleRegistrationConstants.deleteSubscribeAisMv.SUCCESS:
			return {
				...state,
				isSendingChange: false,
				subscriberDelete: action.payload.result ? i18n.messageItemDeleteSuccses[isRu()] : null,
			}
		default:
			return state
	}
}