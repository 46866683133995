import {
    superiorReOrgsConstants
} from "./constants";

const initialState = {
    addSuperiorOrgResult : null,
    removeSuperiorOrgResult : null,
    getPotentialOrgListResult : null,
    getSuperiorOrgsPagedResult : null,
    getSuperiorOrgsListResult : null,

    addSuperiorOrgLoading : false,
    removeSuperiorOrgLoading : false,
    getPotentialOrgListLoading : false,
    getSuperiorOrgsPagedLoading : false,
    getSuperiorOrgsListLoading : false,
}

export default function (state = initialState, action){
	switch (action.type){
		case superiorReOrgsConstants.AddSuperiorOrg.REQUEST:
			return {
				...state,
                addSuperiorOrgResult  : null,
				addSuperiorOrgLoading : true
			}
        case superiorReOrgsConstants.AddSuperiorOrg.SUCCESS:
			return {
				...state,
                addSuperiorOrgResult  : action.payload.result,
				addSuperiorOrgLoading : false
			}
		case superiorReOrgsConstants.AddSuperiorOrg.FAILURE:
			return {
				...state,
                addSuperiorOrgResult  : null,
				addSuperiorOrgLoading : false,
                error                 : action.payload.result
			}
        case superiorReOrgsConstants.AddSuperiorOrg.CLEAR:
            return {
                ...state,
                addSuperiorOrgResult  : null,
                addSuperiorOrgLoading : false
            }

        case superiorReOrgsConstants.RemoveSuperiorOrg.REQUEST:
			return {
				...state,
                removeSuperiorOrgResult  : null,
				removeSuperiorOrgLoading : true
			}
        case superiorReOrgsConstants.RemoveSuperiorOrg.SUCCESS:
			return {
				...state,
                removeSuperiorOrgResult  : action.payload.result,
				removeSuperiorOrgLoading : false
			}
		case superiorReOrgsConstants.RemoveSuperiorOrg.FAILURE:
			return {
				...state,
                removeSuperiorOrgResult  : null,
				removeSuperiorOrgLoading : false,
                error                 : action.payload.result
			}
        case superiorReOrgsConstants.RemoveSuperiorOrg.CLEAR:
            return {
                ...state,
                removeSuperiorOrgResult  : null,
                removeSuperiorOrgLoading : false
            }

        case superiorReOrgsConstants.GetPotentialOrgList.REQUEST:
			return {
				...state,
                getPotentialOrgListResult  : null,
				getPotentialOrgListLoading : true
			}
        case superiorReOrgsConstants.GetPotentialOrgList.SUCCESS:
			return {
				...state,
                getPotentialOrgListResult  : action.payload.result,
				getPotentialOrgListLoading : false
			}
		case superiorReOrgsConstants.GetPotentialOrgList.FAILURE:
			return {
				...state,
                getPotentialOrgListResult  : null,
				getPotentialOrgListLoading : false,
                error                 : action.payload.result
			}
        case superiorReOrgsConstants.GetPotentialOrgList.CLEAR:
            return {
                ...state,
                getPotentialOrgListResult  : null,
                getPotentialOrgListLoading : false
            }

        case superiorReOrgsConstants.GetSuperiorOrgsPaged.REQUEST:
			return {
				...state,
                getSuperiorOrgsPagedResult  : null,
				getSuperiorOrgsPagedLoading : true
			}
        case superiorReOrgsConstants.GetSuperiorOrgsPaged.SUCCESS:
			return {
				...state,
                getSuperiorOrgsPagedResult  : action.payload.result,
				getSuperiorOrgsPagedLoading : false
			}
		case superiorReOrgsConstants.GetSuperiorOrgsPaged.FAILURE:
			return {
				...state,
                getSuperiorOrgsPagedResult  : null,
				getSuperiorOrgsPagedLoading : false,
                error                 : action.payload.result
			}
        case superiorReOrgsConstants.GetSuperiorOrgsPaged.CLEAR:
            return {
                ...state,
                getSuperiorOrgsPagedResult  : null,
                getSuperiorOrgsPagedLoading : false
            }
        
        case superiorReOrgsConstants.GetSuperiorOrgsList.REQUEST:
			return {
				...state,
                getSuperiorOrgsListResult  : null,
				getSuperiorOrgsListLoading : true
			}
        case superiorReOrgsConstants.GetSuperiorOrgsList.SUCCESS:
			return {
				...state,
                getSuperiorOrgsListResult  : action.payload.result,
				getSuperiorOrgsListLoading : false
			}
		case superiorReOrgsConstants.GetSuperiorOrgsList.FAILURE:
			return {
				...state,
                getSuperiorOrgsListResult  : null,
				getSuperiorOrgsListLoading : false,
                error                 : action.payload.result
			}
        case superiorReOrgsConstants.GetSuperiorOrgsList.CLEAR:
            return {
                ...state,
                getSuperiorOrgsListResult  : null,
                getSuperiorOrgsListLoading : false
            }
        case superiorReOrgsConstants.ClearSuperiorOrgsValues:
            return {
                ...state,
                addSuperiorOrgResult  : null,
                removeSuperiorOrgResult  : null,
                getPotentialOrgListResult  : null
            }
        default :
			return state
    }
}
