export const messageConstants = {
	sendMessage: {
		REQUEST : "SEND_MESSAGE_REQUEST",
		SUCCESS : "SEND_MESSAGE_SUCCESS",
		FAILURE : "SEND_MESSAGE_FAILURE",
		CLEAR   : "SEND_MESSAGE_CLEAR"
	},
	getChats: {
		REQUEST  : "GET_CHATS_REQUEST",
		SUCCESS  : "GET_CHATS_SUCCESS",
		FAILURE  : "GET_CHATS_FAILURE",
		REDIRECT : "GET_CHATS_REDIRECT"
	},
	getMessages: {
		REQUEST : "GET_MESSAGES_REQUEST",
		SUCCESS : "GET_MESSAGES_SUCCESS",
		FAILURE : "GET_MESSAGES_FAILURE"
	},
	findMessages: {
		REQUEST : "FIND_MESSAGES_REQUEST",
		SUCCESS : "FIND_MESSAGES_SUCCESS",
		FAILURE : "FIND_MESSAGES_FAILURE"
	},
	downloadFile: {
		REQUEST : "DOWNLOAD_MESSAGE_FILE_REQUEST",
		SUCCESS : "DOWNLOAD_MESSAGE_FILE_SUCCESS",
		FAILURE : "DOWNLOAD_MESSAGE_FILE_FAILURE"
	},
	deleteMessage: {
		REQUEST : "DELETE_MESSAGE_REQUEST",
		SUCCESS : "DELETE_MESSAGE_SUCCESS",
		FAILURE : "DELETE_MESSAGE_FAILURE"
	},
	changeMessageChat: {
		REQUEST : "CHANGE_MESSAGE_CHAT_REQUEST",
		SUCCESS : "CHANGE_MESSAGE_CHAT_SUCCESS",
		FAILURE : "CHANGE_MESSAGE_CHAT_FAILURE"
	}
}
