export const tableReportsConstants = {
    generateReport : {
        REQUEST : "GENERATE_TABLE_REPORT_REQUEST",
        SUCCESS : "GENERATE_TABLE_REPORT_SUCCESS",
        FAILURE : "GENERATE_TABLE_REPORT_FAILURE",
        CLEAR   : "GENERATE_TABLE_REPORT_CLEAR"
    },
    getSettingsListForReportGenerator: {
        REQUEST : "GET_SETTINGS_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_SETTINGS_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_SETTINGS_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_SETTINGS_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getRegionsListForReportGenerator: {
        REQUEST : "GET_REGIONS_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_REGIONS_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_REGIONS_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_REGIONS_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getDistrictsListForReportGenerator: {
        REQUEST : "GET_DISTRICTS_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_DISTRICTS_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_DISTRICTS_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR : "GET_DISTRICTS_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getRegionCitiesListForReportGenerator: {
        REQUEST : "GET_REGION_CITIES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_REGION_CITIES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_REGION_CITIES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR : "GET_REGION_CITIES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getSubthemesListForReportGenerator: {
        REQUEST : "GET_SUBTHEMES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_SUBTHEMES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_SUBTHEMES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_SUBTHEMES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getThemesListForReportGenerator: {
        REQUEST : "GET_THEMES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_THEMES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_THEMES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_THEMES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getAppealTypesListForReportGenerator: {
        REQUEST : "GET_APPEAL_TYPES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_APPEAL_TYPES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_APPEAL_TYPES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_APPEAL_TYPES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getAppealViewTypesListForReportGenerator: {
        REQUEST : "GET_APPEAL_VIEW_TYPES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_APPEAL_VIEW_TYPES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_APPEAL_VIEW_TYPES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_APPEAL_VIEW_TYPES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getReOrgAppealStatusesListForReportGenerator: {
        REQUEST : "GET_REORG_APPEAL_STATUSES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_REORG_APPEAL_STATUSES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_REORG_APPEAL_STATUSES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_REORG_APPEAL_STATUSES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getAppealFormTypesListForReportGenerator: {
        REQUEST : "GET_APPEAL_FORM_TYPES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_APPEAL_FORM_TYPES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_APPEAL_FORM_TYPES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_APPEAL_FORM_TYPES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getApplicantTypesListForReportGenerator: {
        REQUEST : "GET_APPLICANT_TYPES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_APPLICANT_TYPES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_APPLICANT_TYPES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_APPLICANT_TYPES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getReOrgsListForReportGenerator: {
        REQUEST : "GET_REORGS_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_REORGS_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_REORGS_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_REORGS_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getReOrgAppealTerritoriesListForReportGenerator: {
        REQUEST : "GET_RE_ORG_APPEAL_TERRITORIES_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_RE_ORG_APPEAL_TERRITORIES_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_RE_ORG_APPEAL_TERRITORIES_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_RE_ORG_APPEAL_TERRITORIES_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getSuperiorOrgsListForReportGenerator: {
        REQUEST : "GET_SUPERIOR_ORGS_LIST_FOR_REPORT_GENERATOR_REQUEST",
        SUCCESS : "GET_SUPERIOR_ORGS_LIST_FOR_REPORT_GENERATOR_SUCCESS",
        FAILURE : "GET_SUPERIOR_ORGS_LIST_FOR_REPORT_GENERATOR_FAILURE",
        CLEAR   : "GET_SUPERIOR_ORGS_LIST_FOR_REPORT_GENERATOR_CLEAR"
    },
    getAllReOrgReportSettingsList: {
        REQUEST : "GET_ALL_REORG_REPORT_SETTINGS_LIST_REQUEST",
        SUCCESS : "GET_ALL_REORG_REPORT_SETTINGS_LIST_SUCCESS",
        FAILURE : "GET_ALL_REORG_REPORT_SETTINGS_LIST_FAILURE",
        CLEAR   : "GET_ALL_REORG_REPORT_SETTINGS_LIST_CLEAR"
    },
    getCurrentReOrgReportSetting: {
        REQUEST : "GET_CURRENT_RE_ORG_REPORT_SETTING_REQUEST",
        SUCCESS : "GET_CURRENT_RE_ORG_REPORT_SETTING_SUCCESS",
        FAILURE : "GET_CURRENT_RE_ORG_REPORT_SETTING_FAILURE",
        CLEAR   : "GET_CURRENT_RE_ORG_REPORT_SETTING_CLEAR"
    },
    createReOrgReportSetting: {
        REQUEST : "CREATE_REORG_REPORT_SETTING_REQUEST",
        SUCCESS : "CREATE_REORG_REPORT_SETTING_SUCCESS",
        FAILURE : "CREATE_REORG_REPORT_SETTING_FAILURE",
        CLEAR   : "CREATE_REORG_REPORT_SETTING_CLEAR"
    },
    updateReOrgReportSetting: {
        REQUEST : "UPDATE_REORG_REPORT_SETTING_REQUEST",
        SUCCESS : "UPDATE_REORG_REPORT_SETTING_SUCCESS",
        FAILURE : "UPDATE_REORG_REPORT_SETTING_FAILURE",
        CLEAR   : "UPDATE_REORG_REPORT_SETTING_CLEAR"
    },
    deleteReOrgReportSetting: {
        REQUEST : "DELETE_REORG_REPORT_SETTING_REQUEST",
        SUCCESS : "DELETE_REORG_REPORT_SETTING_SUCCESS",
        FAILURE : "DELETE_REORG_REPORT_SETTING_FAILURE",
        CLEAR   : "DELETE_REORG_REPORT_SETTING_CLEAR"
    },
    clearCRUDResultForReportGenerator: "CLEAR_CRUD_RESULT_FOR_REPORT_GENERATOR"
}