import {
	requests
}from '../../../api/agent'

export default {
	changeMessageChat   : (params) => requests.getWithParams('/message/changeMessageChat', params),
	deleteMessage       : (params) => requests.getWithParams('/message/deleteMessage', params),
	downloadMessageFile : (params) => requests.getFile('/message/downloadFile', params),
	findMessages        : (params) => requests.getWithParams('/message/findMessages', params),
	getChats            : (params) => requests.getWithParams('/message/getChats', params),
	getMessages         : (params) => requests.getWithParams('/message/getMessages', params),
	sendMessage         : (data) => requests.post('/message/sendMessage', data)
}