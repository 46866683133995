export const uploadJsonFileConstants = {

	UploadJsonFiles: {
		REQUEST : "UPLOAD_JSONFILE_REQUEST",
		SUCCESS : "UPLOAD_JSONFILE_SUCCESS",
		FAILURE : "UPLOAD_JSONFILE_FAILURE"
	},
	DownloadNRIFile: {
		REQUEST : "DOWLOAD_NRI_REQUEST",
		SUCCESS : "DOWLOAD_NRI_SUCCESS",
		FAILURE : "DOWLOAD_NRI_FAILURE"
	},
	DownloadNRIFileV2: {
		FAILURE : "DOWLOAD_NRIV2_FAILURE",
		REQUEST : "DOWLOAD_NRIV2_REQUEST",
		SUCCESS : "DOWLOAD_NRIV2_SUCCESS"
	},
	UnloadAppealsJson: {
		REQUEST : "UNLOAD_APPEALS_REQUEST",
		SUCCESS : "UNLOAD_APPEALS_SUCCESS",
		FAILURE : "UNLAOD_APPEALS_FAILURE"
	},
	UploadUsersFromFile: {
		REQUEST : "UPLOAD_USERS_FROM_FILE_REQUEST",
		SUCCESS : "UPLOAD_USERS_FROM_FILE_SUCCESS",
		FAILURE : "UPLAOD_USERS_FROM_FILE_FAILURE"
	},
	UploadOrgsFromFile: {
		REQUEST : "UPLOAD_ORGS_FROM_FILE_REQUEST",
		SUCCESS : "UPLOAD_ORGS_FROM_FILE_SUCCESS",
		FAILURE : "UPLAOD_ORGS_FROM_FILE_FAILURE"
	},
	ClearFiles: {
		CLEAR: "STORE_FILES_CLEAR"
	}
}