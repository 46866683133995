/* eslint-disable sort-keys */
const i18n = {
	sucsessNotice: {
		0 : "Статус падпіскі зменены",
		1 : "Статус подписки изменен"
	},
	switchText: {
		0 : "Падпісацца на рассылку",
		1 : "Подписаться на рассылку"
	},
	appealNumber: {
		1 : "Номер обращения ",
		0 : "Нумар звароту "
	},
	actDate: {
		0 : "Дата ",
		1 : "Дата "
	},
	actDescription: {
		0 : "Апісанне падзеі ",
		1 : "Описание события "
	},
	IdMessage: {
		0 : "ID паведамлення",
		1 : "ID сообщения"
	},
	type: {
		0 : "Тып паведамлення ",
		1 : "Тип сообщения "
	},
	status: {
		0 : "Статус ",
		1 : "Cтатус "
	},
	appealNumberPlaceholder: {
		0 : "Увядзіце нумар звароту ",
		1 : "Введите номер обращения "
	},
	inputType: {
		0 : "Абярыце тып паведамлення",
		1 : "Выберите тип сообщения"
	},
	inputStatus: {
		0 : "Абярыце статус",
		1 : "Выберите статус"
	},
	inputDate1: {
		0 : "ад",
		1 : "от"
	},
	inputDate2: {
		0 : " да",
		1 : " до"
	},
	inputRegNumber: {
		0 : "Увядзіце рэгістрацыйны нумар",
		1 : "Введите регистрационный номер"
	},
	inputID: {
		0 : "Увядзіце ID паведамлення / пакета СМДА",
		1 : "Введите ID сообщения / пакета СМДО"
	},
	inputParentId: {
		0 : "Увядзіце ID папярэдняга паведамлення / пакета СМДА",
		1 : "Введите ID предыдущего сообщения / пакета СМДО"
	},
	inputReOrg: {
		0 : "Увядзіце УНП",
		1 : "Введите УНП"
	},
	inputMessageID: {
		0 : "Увядзіце поўны ID паведамлення / пакета СМДА",
		1 : "Введите полный ID сообщения / пакета СМДО"
	},
	reOrg: {
		0 : " УНП",
		1 : "УНП"
	},
	titleButtonSubmit: {
		0 : "Захаваць",
		1 : "Сохранить"
	},
	orgName: {
		0 : "Назва арганізацыі",
		1 : "Название организации"
	},
	description: {
		0 : 'Апiсанне',
		1 : 'Описание'
	},
	action: {
		0 : 'Цела паведамлення',
		1 : 'Тело сообщения'
	},
	showBody: {
		0 : 'Паглядзець',
		1 : 'Посмотреть'
	},
	resetFilters: {
		0 : 'Скінуць фільтры',
		1 : 'Сбросить фильтры'
	},

	//типы сообщения

	AppealMessage: {
		0 : 'Пакет звароту',
		1 : 'Пакет обращения'
	},
	RouterAcknowlegment: {
		0 : 'Апавяшчэнне ад маршрутызатара',
		1 : 'Уведомление от маршрутизатора'
	},
	DeliveryAcknowlegment: {
		0 : 'Апавяшчэнне аб дастаўцы',
		1 : 'Уведомление  о доставке'
	},
	RegisterAcknowlegment: {
		0 : 'Апавяшчэнне аб рэгістрацыі',
		1 : 'Уведомление о регистрации'
	},
	RegisterRejectionAcknowlegment: {
		0 : 'Апавяшчэнне аб адмове ў рэгістрацыі',
		1 : 'Уведомление об отказе в регистрации'
	},
	RegistrationOutDatedNotification: {
		0 : 'Напамін аб неабходнасці рэгістрацыі звароту',
		1 : 'Напоминание о необходимости регистрации обращения'
	},
	ExecutionOutDatedNotification: {
		0 : 'Напамін аб неабходнасці рэгістрацыі звароту',
		1 : 'Напоминание о необходимости исполнения обращения'
	},
	ExternalSystemMessage: {
		0 : 'Паведамленне ад знешняй СЭД',
		1 : 'Сообщение от внешней СЭД'
	},
	RecallByCreatorNotification: {
		0 : "Апавяшчэнне аб адкліканні звароту заяўнікам",
		1 : "Уведомление об отзыве обращения заявителем"
	},
	defaultType: {
		0 : 'Напамін аб неабходнасці выканання звароту',
		1 : 'Напоминание о необходимости исполнения обращения'
	},

	//Статусы сообщения

	Created: {
		0 : 'Створана лакальна',
		1 : 'Создано локально'
	},
	SendedToRouter: {
		0 : 'Адпраўлена ў маршрутызатар СМДА',
		1 : 'Отправлено в маршрутизатор СМДО'
	},
	Delivery: {
		0 : 'Прынята СЭД абанента',
		1 : 'Принято СЭД абонента'
	},
	Register: {
		0 : 'Зарэгістравана СЭД абанента',
		1 : 'Зарегистрировано СЭД абонента'
	},
	RegisterRejection: {
		0 : 'Адмоўлена ў рэгістрацыі СЭД абанента',
		1 : 'Отказано в регистрации СЭД абонента'
	},
	RouterError: {
		0 : 'Памылка загрузкі ў маршрутызатар',
		1 : 'Ошибка загрузки в маршрутизатор'
	},
	SEDSystemError: {
		0 : 'Памылка пры загрузцы ў СЭД абанента',
		1 : 'Ошибка при загрузке в СЭД абонента'
	},
	RegisterRejectionByLocalSystem: {
		0 : 'Адмоўлена ў рэгістрацыі сістэмай зваротаў',
		1 : 'Отказано в регистрации системой обращений'
	},
	CreatedOnRouter: {
		0 : 'Загружана ў маршрутызатар СМДА',
		1 : 'Загружено в маршрутизатор СМДО'
	},
	Received: {
		0 : 'Прынята сістэмай зваротаў',
		1 : 'Принято системой обращений'
	},
	RouterVerificationCompleted: {
		0 : 'Прайшло праверку маршрутызатара',
		1 : 'Прошло проверку маршрутизатора'
	},
	CreationError: {
		0 : "Памылка пры стварэнні паведамлення",
		1 : "Ошибка при создании сообщения"
	},
	ReadyForUpload: {
		0 : "Гатовы да адпраўкі",
		1 : "Готов к отправке"
	},
	defaultStatus: {
		0 : 'Адпраўлена ў маршрутызатар СМДА',
		1 : 'Пакет загружен в маршрутизатор'
	},

	attachedFiles: {
		0 : 'Укладзеныя файлы',
		1 : 'Вложенные файлы'
	},
	messageBody: {
		0 : 'Цела паведамлення ў фармаце json:',
		1 : 'Тело сообщения в формате json:'
	},
	filters: {
		0 : 'Фільтры',
		1 : 'Фильтры'
	},
	parentMessage: {
		0 : 'ID папярэдняга паведамлення',
		1 : 'ID предыдущего сообщения'
	},
	parentPackageFilter: {
		0 : "Увядзіце поўны ID папярэдняга паведамлення / пакета СМДА",
		1 : "Введите полный ID предыдущего сообщения / пакета СМДО"
	},
	documnetSystemRegNumber: {
		0 : "Рэгістрацыйны нумар ў сістэме",
		1 : "Регистрационный номер в системе"
	},
	inputDocumnetSystemRegNumber: {
		0 : "Увядзіце рэгістрацыйны нумар ў сістэме",
		1 : "Введите регистрационный номер в системе"
	},
	addToAppealFilesTooltip: {
		0 : "Вы можаце дадаць файлы, атрыманыя праз СМДА па дадзеным звароту",
		1 : "Вы можете добавить файлы, полученные через СМДО по данному обращению"
	},
	fromAisMvFiles: {
		0 : "Файлы з СМДА",
		1 : "Файлы из СМДО"
	},
	selectFromAisMV: {
		0 : "Выбраць з СМДА",
		1 : "Выбрать из СМДО"
	},
	notChoiseOrg: {
		0 : "Адключыць рассылку",
		1 : "Отключить рассылку"
	},
	aisMvReciever: {
		0 : "Абанент СМДА",
		1 : "Абонент  СМДО"
	},
	mustSelectRecieverError: {
		0 : "Выберыце абанента СМДА",
		1 : "Выберите абонента СМДО"
	},
	recieverOrgName: {
		0 : "Назва арганізацыі-абанента",
		1 : "Наименование организации-абонента"
	},
	subscriberSearchPlaceholder: {
		0 : "Увядзіце назву абанента альбо код СМДО",
		1 : "Введите наименование абонента  либо код СМДО"
	},
	titleModalSubscribers: {
		0 : "Выбар абанента для рассылкі",
		1 : "Выберите абонента для рассылки"
	}
}
export default i18n
