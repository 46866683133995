export const notificationSettingsConstants = {
  Get: {
    REQUEST: "GET_NOTIFICATION_SETTINGS_REQUEST",
    SUCCESS: "GET_NOTIFICATION_SETTINGS_SUCCESS",
    FAILURE: "GET_NOTIFICATION_SETTINGS_FAILURE",
  },
  Set: {
    REQUEST: "SET_NOTIFICATION_SETTINGS_REQUEST",
    SUCCESS: "SET_NOTIFICATION_SETTINGS_SUCCESS",
    FAILURE: "SET_NOTIFICATION_SETTINGS_FAILURE",
  }
};
