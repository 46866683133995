import {
	messageConstants
}from "./constants"
import notice from "../../../components/Notice"

const initialState = {
	//sendMessage;
	getSending     : false,
	getSendError   : null,
	getLoadingSend : false,

	//getChats
	getLoadingChats : false,
	chats           : [],
	getChatsError   : null,

	//getMessages
	getLoadingMessages : false,
	messages           : [],
	getMessagesError   : null,

	//findMessages
	findLoadingMessages : false,
	foundData           : {},
	findMessagesError   : null,

	//downloadMessageFile
	getMessageFileError : null,
	messageFile         : null,
	messageFile         : null,

	//changeMessageChat
	changeMessageChatError: null,

	//linkToDialog
	redirectUser: null
}

export default function (state = initialState, action){
	switch (action.type){
		//sendMessage
		case messageConstants.sendMessage.REQUEST :
			return {
				...state,
				getSendError   : null,
				getSending     : false,
				getLoadingSend : true
			}
		case messageConstants.sendMessage.SUCCESS :
			return {
				...state,
				getSending     : action.payload.result,
				getLoadingSend : false
			}
		case messageConstants.sendMessage.FAILURE :
			return {
				...state,
				getSending     : false,
				getLoadingSend : false,
				getSendError   : action.payload.err.data
					? action.payload.err.data.errors
					: action.payload.err
			}
		case messageConstants.sendMessage.CLEAR :
			return {
				...state,
				getSending          : false,
				getLoadingSend      : false,
				getLoadingChats     : false,
				chats               : [],
				getChatsError       : null,
				getLoadingMessages  : false,
				messages            : [],
				findLoadingMessages : false,
				foundData           : {},
				messageFile         : null,
				redirectUser        : null
			}

			//getChats
		case messageConstants.getChats.REQUEST :
			return {
				...state,
				getLoadingMessages  : false,
				findLoadingMessages : false,
				getChatsError       : null,
				getLoadingChats     : false,
				chats               : [],
				foundData           : {}
			}
		case messageConstants.getChats.SUCCESS :
			return {
				...state,
				getLoadingChats : true,
				chats           : action.payload.result
			}
		case messageConstants.getChats.FAILURE :
			return {
				...state,
				chats           : [],
				foundData       : {},
				getLoadingChats : true,
				getChatsError   : action.payload.err.data.errors
			}
		case messageConstants.getChats.REDIRECT :
			return {
				...state,
				redirectUser: action.payload.redirectUser
			}

			//findMessages
		case messageConstants.findMessages.REQUEST :
			return {
				...state,
				redirectUser        : null,
				getLoadingMessages  : false,
				findLoadingMessages : false,
				getLoadingChats     : false,
				foundData           : {},
				chats               : [],
				findMessagesError   : null
			}
		case messageConstants.findMessages.SUCCESS :
			return {
				...state,
				findLoadingMessages : true,
				foundData           : action.payload.result
			}
		case messageConstants.findMessages.FAILURE :
			return {
				...state,
				chats               : [],
				foundData           : {},
				findLoadingMessages : true,
				findMessagesError   : action.payload.err.data.errors
			}

			//getMessages
		case messageConstants.getMessages.REQUEST :{
			return {
				...state,
				getMessagesError   : null,
				messages           : [],
				getLoadingMessages : false,
				redirectUser       : null
			}
		}
		case messageConstants.getMessages.SUCCESS :
			return {
				...state,
				messages           : action.payload.result,
				getLoadingMessages : true
			}
		case messageConstants.getMessages.FAILURE :
			return {
				...state,
				idRedirect         : null,
				messages           : [],
				getMessagesError   : action.payload.err.data.errors,
				getLoadingMessages : false
			}

			//downloadMessageFile
		case messageConstants.downloadFile.REQUEST :
			return {
				...state,
				getMessageFileError : null,
				messageFile         : null
			}
		case messageConstants.downloadFile.SUCCESS :
			return {
				...state,
				messageFile: action.payload.result
			}
		case messageConstants.downloadFile.FAILURE :
			return {
				...state,
				messageFile         : null,
				getMessageFileError : action.payload.err.data.errors
			}

			//deleteMessage;
		case messageConstants.deleteMessage.REQUEST :
			return {
				...state,
				getSendError   : null,
				getSending     : false,
				getLoadingSend : true
			}
		case messageConstants.deleteMessage.SUCCESS :
			return {
				...state,
				getSending     : action.payload.result,
				getLoadingSend : false
			}
		case messageConstants.deleteMessage.FAILURE :
			return {
				...state,
				getSending     : false,
				getLoadingSend : false,
				getSendError   : action.payload.err.data.errors
			}

			//changeMessageChat;
		case messageConstants.changeMessageChat.REQUEST :
			return {
				...state,
				changeMessageChatError : null,
				getSending             : false,
				getLoadingSend         : true
			}
		case messageConstants.changeMessageChat.SUCCESS :
			return {
				...state,
				getSending     : action.payload.result,
				getLoadingSend : false
			}
		case messageConstants.changeMessageChat.FAILURE :
			return {
				...state,
				getSending             : false,
				getLoadingSend         : false,
				changeMessageChatError : action.payload.err.data.errors
			}
		default :
			return state
	}
}
