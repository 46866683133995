export const mobileConstants = {
         GetCodes: {
           REQUEST: "GET_CODES_REQUEST",
           SUCCESS: "GET_CODES_SUCCESS",
           FAILURE: "GET_CODES_FAILURE",
         },
         SendCode: {
           REQUEST: "SEND_CODE_REQUEST",
           SUCCESS: "SEND_CODE_SUCCESS",
           FAILURE: "SEND_CODE_FAILURE",
           CLEAR: "SEND_CODE_CLEAR",
         },
         CheckCode: {
           REQUEST: "CHECK_CODE_REQUEST",
           SUCCESS: "CHECK_CODE_SUCCESS",
           FAILURE: "CHECK_CODE_FAILURE",
         },
         ChangeNumber: {
           REQUEST: "CHANGE_NUMBER_REQUEST",
           SUCCESS: "CHANGE_NUMBER_SUCCESS",
           FAILURE: "CHANGE_NUMBER_FAILURE",
         },
         ClearFields: {
           REQUEST: "CLEAR_FIELDS_REQUEST",
           SUCCESS: "CLEAR_FIELDS_SUCCESS",
           FAILURE: "CLEAR_FIELDS_FAILURE",
         },
         ClearPhoneCodes: "CLEAR_PHONE_CODES",
       };
