import { sessionsConstants } from "./constants";
import notice from "../../../../components/Notice";

const initialState = {
  //get;
  getLoading: false,
  sessions: null,
  getError: null,
  //remove;
  removeLoading: false,
  isRemoved: null,
  removeError: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    //get;
    case sessionsConstants.Get.REQUEST:
      return {
        ...state,
        getLoading: true,
        isRemoved: null,
        sessions: null,
        getError: null,
      };
    case sessionsConstants.Get.SUCCESS:
      return {
        ...state,
        getLoading: false,
        sessions: action.payload.result,
      };
    case sessionsConstants.Get.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        getLoading: false,
        getError: action.payload.err.data.errors,
      };
    //remove;
    case sessionsConstants.Remove.REQUEST:
      return {
        ...state,
        removeLoading: true,
        isRemoved: null,
        removeError: null,
      };
    case sessionsConstants.Remove.SUCCESS:
      return {
        ...state,
        removeLoading: false,
        isRemoved: action.payload.result,
      };
    case sessionsConstants.Remove.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        removeLoading: false,
        removeError: action.payload.err.data.errors,
      };
    default:
      return state;
  }
}
