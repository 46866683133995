const i18n = {
	positionInfoTimeLine: {
		1 : (item, unit, start, end) => `${ item.name } ${ (unit ? `(${ unit.name }) ` : ``) }c ${ start } по ${ end }`,
		0 : (item, unit, start, end) => `${ item.nameBel } ${ (unit ? `(${ unit.nameBel }) ` : ``) }з ${ start } па ${ end }`
	},
	positionInfoTitle: {
		1 : "Информация о должности",
		0 : "Інфармацыя пра пасаду"
	},
	orgNamePlaceHolder: {
		0 : "Увядзіце назву арганізацыі",
		1 : "Введите название организации"
	},
	orgName: {
		0 : 'Назва арганізацыі',
		1 : 'Название организации'
	},
	actionsInfoTitle: {
		1 : "Действия",
		0 : "Дзеяння"
	},
	rolesInfoTitle: {
		1 : "Дополнительные роли: ",
		0 : "Дадатковыя ролі: "
	},
	positionName: {
		1 : "Наименование должности",
		0 : "Найменне пасады"
	},
	startDate: {
		1 : "Дата назначения",
		0 : "Дата прызначэння"
	},
	endDate: {
		1 : "Дата окончания",
		0 : "Дата заканчэння"
	},
	changePosition: {
		1 : "Сменить должность",
		0 : "Змяніць пасаду"
	},
	setTemporaryRights: {
		1 : "Назначить временные права внешнего администратора",
		0 : "Прызначыць часовыя правы знешняга адміністратара"
	},
	unsetTemporaryRights: {
		1 : "Снять временные права внешнего администратора",
		0 : "Зняць часовыя правы знешняга адміністратара"
	},
	changeRoles: {
		1 : "Изменить список дополнительных ролей",
		0 : "Змяніць спіс дадатковых роляў"
	},
	confirmUnset: {
		1 : "Вы действительно хотите снять временные права внешнего администратора?",
		0 : "Вы сапраўды хочаце зняць часовыя правы вонкавага адміністратара?"
	},
	reassign: {
		1 : "Направить в подразделение",
		0 : "Накіраваць у падраздзяленне"
	},
	reassignSubmit: {
		1 : "Направить",
		0 : "Накіраваць"
	},
	externalAdminRights: {
		1 : "Назначить права внешнего администратора",
		0 : "Прызначыць правы знешняга адміністратара"
	},
	dismiss: {
		1 : "Уволить",
		0 : "Звольніць"
	},
	confirmDismiss: {
		1 : "Вы действительно хотите уволить пользователя?",
		0 : "Вы сапраўды хочаце звольніць карыстальніка?"
	},
	previousPositions: {
		1 : "Предыдущие должности",
		0 : "Папярэднія пасады"
	},
	positionNotSet: {
		1 : "Должность не назначена",
		0 : "Пасада не прызначана"
	},
	setPosition: {
		1 : "Назначить должность",
		0 : "Прызначыць пасаду"
	},
	saveChanges: {
		1 : "Сохранить изменения",
		0 : "Захаваць змены"
	},
	successSetUserExecut: {
		1 : "Данные пользователя успешно изменены!",
		0 : "Дадзеныя карыстальніка паспяхова зменены!"
	},
	successSetTempExternalAdmin: {
		1 : "Временные права внешнего администратор успешно назначены!",
		0 : "Часовыя правы знешняга адміністратар паспяхова прызначаныя!"
	},
	successUnsetTempExternalAdmin: {
		1 : "Временные права внешнего администратор успешно сняты!",
		0 : "Часовыя правы знешняга адміністратар паспяхова зняты!"
	},
	removeUserFromReOrg: {
		1 : "Действие прошло успешно!",
		0 : "Дзеянне прайшло паспяхова!"
	},
	removeDisciplinaryActions: {
		1 : "Действие прошло успешно!",
		0 : "Дзеянне прайшло паспяхова!"
	},
	isRequired: {
		1 : "Поле обязательно для заполнения.",
		0 : "Поле абавязкова для запаўнення."
	},
	successInviteToReOrg: {
		1 : "Приглашение успешно отправлено!",
		0 : "Запрашэнне паспяхова адпраўлена!"
	},
	successResponseToInvitationLoading: {
		1 : "Ответ на приглашение успешно отправлен!",
		0 : "Адказ на запрашэнне паспяхова адпраўлены!"
	},
	createCreateDisciplinaryAction: {
		1 : "Привлечь к ответственности",
		0 : "Прыцягнуць да адказнасці"
	},
	successChangeRoles: {
		1 : "Изменение списка дополнительных ролей прошло успешно!",
		0 : "Змена спісу дадатковых палёў прайшло паспяхова!"
	},
	successAddChildOrg: {
		1 : "Запрос успешно отправлен!",
		0 : "Запыт паспяхова адпраўлены!"
	},
	successRemoveChildOrg: {
		1 : "Организация успешно удалена из списка!",
		0 : "Арганізацыя паспяхова выдаленая з спісу!"
	},
	placeholderReason: {
		0 : "Пазначце прычыну для часовага выканання абавязкаў",
		1 : "Выберите причину для временного исполнения обязанностей"
	},
	reason: {
		0 : "Прычына",
		1 : "Причина"
	},
	save: {
		0 : "Захаваць",
		1 : "Сохранить"
	}
}

export default i18n
