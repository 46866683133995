import {
	userConstants
}from "../../../../store/constants/user.constants"
import {
	externalsConstants
}from './constants'

const initialState =
{
	error          : null,
	registerResult : null,
	resetResult    : null,
	isSending      : false,
	getResult      : {}
}

export default function (state = initialState, action){
	switch (action.type){
		case externalsConstants.GetApi.REQUEST:
			return {
				...state,
				isSending : true,
				getResult : {}
			}
		case externalsConstants.GetApi.SUCCESS:
			return {
				...state,
				isSending : false,
				getResult : action.payload
			}
		case externalsConstants.GetApi.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload,
				getResult : {}
			}
		case externalsConstants.GetApi.CLEAR:
			return {
				...state,
				isSending : false,
				error     : null,
				getResult : {}
			}
		case externalsConstants.RegisterExternalSystem.REQUEST:
			return {
				...state,
				isSending : true,
				result    : {}
			}
		case externalsConstants.RegisterExternalSystem.SUCCESS:
			return {
				...state,
				isSending : false,
				result    : action.payload
			}

		case externalsConstants.RegisterExternalSystem.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload,
				result    : {}
			}
		case externalsConstants.ResetRegistrationData.REQUEST:
			return {
				...state,
				isSending : true,
				result    : {}
			}
		case externalsConstants.ResetRegistrationData.SUCCESS:
		{
			return {
				...state,
				isSending : false,
				result    : action.payload
			}
		}
		case externalsConstants.ResetRegistrationData.FAILURE:
			return {
				...state,
				isSending : false,
				error     : action.payload
			}
		default:
			return state
		case externalsConstants.ResetRegistrationData.CLEAR:
			return {
				...state,
				result: null
			}
	}
}

/* export default function (state = initialState, action) {
     switch (action.type) {
       case userConstants.Logout.SUCCESS:
         return {
           ...state,
           isSending: false,
           currentItem: {},
         }
       case externalsConstants.SEND_EXTERNALS_REQUEST:
         return {
           ...state,
           isSending: true
         }
       case externalsConstants.SEND_EXTERNALS_SUCCESS:
         return {
           ...state,
           isSending: false,
           currentItem: action.payload
         }
       case externalsConstants.SEND_EXTERNALS_FAILURE:
         return {
           ...state,
           isSending: false,
           error: action.payload
         }
       default:
         return state
     }
   } */