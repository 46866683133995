import {
    tableReportsConstants
} from "./constants";

const initialState = {
    settingsListForReportGenerator : null,
    settingsListForReportGeneratorLoading : false,
    
    generateReportLoading      : false,

    regionsList                : null,
    regionsListLoading         : false,
    districtList               : null,
    districtListLoading        : false,
    regionCitiesList           : null,
    regionCitiesListLoading    : false,
    subthemesList              : null,
    subthemesListLoading       : false,
    themesList                 : null,
    themesListLoading          : false,
    appealTypesList            : null,
    appealTypesListLoading     : false,
    appealViewTypesList        : null,
    appealViewTypesListLoading : false,
    reOrgAppealStatusesList    : null,
    reOrgAppealStatusesListLoading : false,
    appealFormTypesList        : null,
    appealFormTypesListLoading : false,
    applicantTypesList         : null,
    applicantTypesListLoading  : false,
    reOrgAppealTerritoriesList : null,
    reOrgAppealTerritoriesListLoading : false,
    superiorOrgsList           : null,
    superiorOrgsListLoading    : false,

    reOrgsList                 : null,
    reOrgsListLoading          : false,

    reOrgReportSettingsList    : null,
    reOrgReportSettingsListLoading : false,

    currentReOrgReportSetting  : null,
    currentReOrgReportSettingLoading : false,

    createReOrgReportSettingResult  : null,
    updateReOrgReportSettingResult  : null,
    deleteReOrgReportSettingResult  : null,
    CUDReOrgReportSettingLoading : false,
}

export default function (state = initialState, action){
	switch (action.type){
        //getSettingsListForReportGenerator
		case tableReportsConstants.getSettingsListForReportGenerator.REQUEST:
			return {
				...state,
                settingsListForReportGenerator: null,
				settingsListForReportGeneratorLoading : true
			}
        case tableReportsConstants.getSettingsListForReportGenerator.SUCCESS:
			return {
				...state,
                settingsListForReportGenerator : action.payload.result,
				settingsListForReportGeneratorLoading : false
			}
		case tableReportsConstants.getSettingsListForReportGenerator.FAILURE:
			return {
				...state,
                settingsListForReportGenerator : null,
				settingsListForReportGeneratorLoading : false,
                error                 : action.payload.result
			}
        case tableReportsConstants.getSettingsListForReportGenerator.CLEAR:
            return {
                ...state,
                settingsListForReportGenerator : null,
                settingsListForReportGeneratorLoading : false
            }
        //generateReport
        case tableReportsConstants.generateReport.REQUEST:
			return {
				...state,
				generateReportLoading : true
			}
        case tableReportsConstants.generateReport.SUCCESS:
			return {
				...state,
				generateReportLoading : false
			}
		case tableReportsConstants.generateReport.FAILURE:
			return {
				...state,
				generateReportLoading : false,
                error                 : action.payload.result
			}
        case tableReportsConstants.generateReport.CLEAR:
            return {
                ...state,
                generateReportLoading : false
            }
        //getRegionsListForReportGenerator
        case tableReportsConstants.getRegionsListForReportGenerator.REQUEST:
            return {
				...state,
                regionsList           : null,
				regionsListLoading    : true
			}
        case tableReportsConstants.getRegionsListForReportGenerator.SUCCESS:
            return {
				...state,
                regionsList           : action.payload.result,
				regionsListLoading    : false
			}
        case tableReportsConstants.getRegionsListForReportGenerator.FAILURE:
            return {
				...state,
				regionsListLoading    : false,
                error                 : action.payload.result
			}
        case tableReportsConstants.getRegionsListForReportGenerator.CLEAR:
            return {
				...state,
                regionsList           : null,
				regionsListLoading    : false
			}
        //getDistrictsListForReportGenerator
        case tableReportsConstants.getDistrictsListForReportGenerator.REQUEST:
            return {
				...state,
                districtList          : null,
				districtListLoading   : true
			}
        case tableReportsConstants.getDistrictsListForReportGenerator.SUCCESS:
            return {
				...state,
                districtList          : action.payload.result,
				districtListLoading   : false
			}
        case tableReportsConstants.getDistrictsListForReportGenerator.FAILURE:
            return {
				...state,
				districtListLoading   : false,
                error                 : action.payload.result
			}
        case tableReportsConstants.getDistrictsListForReportGenerator.REQUEST:
            return {
				...state,
                districtList          : null,
				districtListLoading   : false
			}
        //getRegionCitiesListForReportGenerator
        case tableReportsConstants.getRegionCitiesListForReportGenerator.REQUEST:
            return {
				...state,
                regionCitiesList        : null,
				regionCitiesListLoading : true
			}
        case tableReportsConstants.getRegionCitiesListForReportGenerator.SUCCESS:
            return {
				...state,
                regionCitiesList        : action.payload.result,
				regionCitiesListLoading : false
			}
        case tableReportsConstants.getRegionCitiesListForReportGenerator.FAILURE:
            return {
				...state,
				regionCitiesListLoading : false,
                error                   : action.payload.result
			}
        case tableReportsConstants.getRegionCitiesListForReportGenerator.CLEAR:
            return {
				...state,
                regionCitiesList        : null,
				regionCitiesListLoading : false
			}
        //getSubthemesListForReportGenerator
        case tableReportsConstants.getSubthemesListForReportGenerator.REQUEST:
            return {
				...state,
                subthemesList           : null,
				subthemesListLoading    : true
			}
        case tableReportsConstants.getSubthemesListForReportGenerator.SUCCESS:
            return {
				...state,
                subthemesList           : action.payload.result,
				subthemesListLoading    : false
			}
        case tableReportsConstants.getSubthemesListForReportGenerator.FAILURE:
            return {
				...state,
				subthemesListLoading    : false
			}
        case tableReportsConstants.getSubthemesListForReportGenerator.CLEAR:
            return {
				...state,
                subthemesList           : null,
				subthemesListLoading    : false
			}
        //getThemesListForReportGenerator
        case tableReportsConstants.getThemesListForReportGenerator.REQUEST:
            return {
				...state,
                themesList           : null,
				themesListLoading    : true
			}
        case tableReportsConstants.getThemesListForReportGenerator.SUCCESS:
            return {
				...state,
                themesList           : action.payload.result,
				themesListLoading    : false
			}
        case tableReportsConstants.getThemesListForReportGenerator.FAILURE:
            return {
				...state,
                error                : action.payload.result,
				themesListLoading    : false
			}
        case tableReportsConstants.getThemesListForReportGenerator.CLEAR:
            return {
				...state,
                themesList           : null,
				themesListLoading    : false
			}
        //getAppealTypesListForReportGenerator
        case tableReportsConstants.getAppealTypesListForReportGenerator.REQUEST:
            return {
				...state,
                appealTypesList           : null,
				appealTypesListLoading    : true
			}
        case tableReportsConstants.getAppealTypesListForReportGenerator.SUCCESS:
            return {
				...state,
                appealTypesList           : action.payload.result,
				appealTypesListLoading    : false
			}
        case tableReportsConstants.getAppealTypesListForReportGenerator.FAILURE:
            return {
				...state,
                error                     : action.payload.result,
				appealTypesListLoading    : false
			}
        case tableReportsConstants.getAppealTypesListForReportGenerator.CLEAR:
            return {
				...state,
                appealTypesList           : null,
				appealTypesListLoading    : false
			}
        //getAppealViewTypesListForReportGenerator
        case tableReportsConstants.getAppealViewTypesListForReportGenerator.REQUEST:
            return {
				...state,
                appealViewTypesList           : null,
				appealViewTypesListLoading    : true
			}
        case tableReportsConstants.getAppealViewTypesListForReportGenerator.SUCCESS:
            return {
				...state,
                appealViewTypesList           : action.payload.result,
				appealViewTypesListLoading    : false
			}
        case tableReportsConstants.getAppealViewTypesListForReportGenerator.FAILURE:
            return {
				...state,
                error                         : action.payload.result,
				appealViewTypesListLoading    : false
			}
        case tableReportsConstants.getAppealViewTypesListForReportGenerator.CLEAR:
            return {
				...state,
                appealViewTypesList           : null,
				appealViewTypesListLoading    : false
			}
        //getReOrgAppealStatusesListForReportGenerator
        case tableReportsConstants.getReOrgAppealStatusesListForReportGenerator.REQUEST:
            return {
				...state,
                reOrgAppealStatusesList           : null,
				reOrgAppealStatusesListLoading    : true
			}
        case tableReportsConstants.getReOrgAppealStatusesListForReportGenerator.SUCCESS:
            return {
				...state,
                reOrgAppealStatusesList           : action.payload.result,
				reOrgAppealStatusesListLoading    : false
			}
        case tableReportsConstants.getReOrgAppealStatusesListForReportGenerator.FAILURE:
            return {
				...state,
                error                             : action.payload.result,
				reOrgAppealStatusesListLoading    : false
			}
        case tableReportsConstants.getReOrgAppealStatusesListForReportGenerator.CLEAR:
            return {
				...state,
                reOrgAppealStatusesList           : null,
				reOrgAppealStatusesListLoading    : false
			}
        //getAppealFormTypesListForReportGenerator
        case tableReportsConstants.getAppealFormTypesListForReportGenerator.REQUEST:
            return {
				...state,
                appealFormTypesList           : null,
				appealFormTypesListLoading    : true
			}
        case tableReportsConstants.getAppealFormTypesListForReportGenerator.SUCCESS:
            return {
				...state,
                appealFormTypesList           : action.payload.result,
				appealFormTypesListLoading    : false
			}
        case tableReportsConstants.getAppealFormTypesListForReportGenerator.FAILURE:
            return {
				...state,
                error           : action.payload.result,
				appealFormTypesListLoading    : false
			}
        case tableReportsConstants.getAppealFormTypesListForReportGenerator.CLEAR:
            return {
				...state,
                appealFormTypesList           : null,
				appealFormTypesListLoading    : false
			}
        //getApplicantTypesListForReportGenerator
        case tableReportsConstants.getApplicantTypesListForReportGenerator.REQUEST:
            return {
				...state,
                applicantTypesList           : null,
				applicantTypesListLoading    : true
			}
        case tableReportsConstants.getApplicantTypesListForReportGenerator.SUCCESS:
            return {
				...state,
                applicantTypesList           : action.payload.result,
				applicantTypesListLoading    : false
			}
        case tableReportsConstants.getApplicantTypesListForReportGenerator.FAILURE:
            return {
				...state,
                error           : action.payload.result,
				applicantTypesListLoading    : false
			}
        case tableReportsConstants.getApplicantTypesListForReportGenerator.CLEAR:
            return {
				...state,
                applicantTypesList           : null,
				applicantTypesListLoading    : false
			}
        //getReOrgsListForReportGenerator
        case tableReportsConstants.getReOrgsListForReportGenerator.REQUEST:
            return {
                ...state,
                reOrgsList : null,
                reOrgsListLoading : true
            }
        case tableReportsConstants.getReOrgsListForReportGenerator.SUCCESS:
            return {
                ...state,
                reOrgsList : action.payload.result,
                reOrgsListLoading : false
            }
        case tableReportsConstants.getReOrgsListForReportGenerator.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                reOrgsListLoading : false
            }
        case tableReportsConstants.getReOrgsListForReportGenerator.CLEAR:
            return {
                ...state,
                reOrgsList : null,
                reOrgsListLoading : false
            }
        //getReOrgAppealTerritoriesListForReportGenerator
        case tableReportsConstants.getReOrgAppealTerritoriesListForReportGenerator.REQUEST:
            return {
                ...state,
                reOrgAppealTerritoriesList : null,
                reOrgAppealTerritoriesListLoading : true
            }
        case tableReportsConstants.getReOrgAppealTerritoriesListForReportGenerator.SUCCESS:
            return {
                ...state,
                reOrgAppealTerritoriesList : action.payload.result,
                reOrgAppealTerritoriesListLoading : false
            }
        case tableReportsConstants.getReOrgAppealTerritoriesListForReportGenerator.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                reOrgAppealTerritoriesListLoading : false
            }
        case tableReportsConstants.getReOrgAppealTerritoriesListForReportGenerator.CLEAR:
            return {
                ...state,
                reOrgAppealTerritoriesList : null,
                reOrgAppealTerritoriesListLoading : false
            }
        //getSuperiorOrgsListForReportGenerator
        case tableReportsConstants.getSuperiorOrgsListForReportGenerator.REQUEST:
            return {
                ...state,
                superiorOrgsList : null,
                superiorOrgsListLoading : true
            }
        case tableReportsConstants.getSuperiorOrgsListForReportGenerator.SUCCESS:
            return {
                ...state,
                superiorOrgsList : action.payload.result,
                superiorOrgsListLoading : false
            }
        case tableReportsConstants.getSuperiorOrgsListForReportGenerator.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                superiorOrgsListLoading : false
            }
        case tableReportsConstants.getSuperiorOrgsListForReportGenerator.CLEAR:
            return {
                ...state,
                superiorOrgsList : null,
                superiorOrgsListLoading : false
            }
        //getAllReOrgReportSettingsList
        case tableReportsConstants.getAllReOrgReportSettingsList.REQUEST: 
            return {
                ...state,
                reOrgReportSettingsList : null,
                reOrgReportSettingsListLoading : false
            }
        case tableReportsConstants.getAllReOrgReportSettingsList.SUCCESS:
            return {
                ...state,
                reOrgReportSettingsList : action.payload.result,
                reOrgReportSettingsListLoading : false
            }
        case tableReportsConstants.getAllReOrgReportSettingsList.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                reOrgReportSettingsListLoading : false
            }
        case tableReportsConstants.getAllReOrgReportSettingsList.CLEAR:
            return {
                ...state,
                reOrgReportSettingsList : null,
                reOrgReportSettingsListLoading : false
            }
        //getCurrentReOrgReportSetting
        case tableReportsConstants.getCurrentReOrgReportSetting.REQUEST: 
            return {
                ...state,
                currentReOrgReportSetting : null,
                currentReOrgReportSettingLoading : false
            }
        case tableReportsConstants.getCurrentReOrgReportSetting.SUCCESS:
            return {
                ...state,
                currentReOrgReportSetting : action.payload.result,
                currentReOrgReportSettingLoading : false
            }
        case tableReportsConstants.getCurrentReOrgReportSetting.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                currentReOrgReportSettingLoading : false
            }
        case tableReportsConstants.getCurrentReOrgReportSetting.CLEAR:
            return {
                ...state,
                currentReOrgReportSetting : null,
                currentReOrgReportSettingLoading : false
            }
        //createReOrgReportSetting
        case tableReportsConstants.createReOrgReportSetting.REQUEST: 
            return {
                ...state,
                createReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.createReOrgReportSetting.SUCCESS:
            return {
                ...state,
                createReOrgReportSettingResult : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.createReOrgReportSetting.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.createReOrgReportSetting.CLEAR:
            return {
                ...state,
                createReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
        //updateReOrgReportSetting
        case tableReportsConstants.updateReOrgReportSetting.REQUEST: 
            return {
                ...state,
                updateReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.updateReOrgReportSetting.SUCCESS:
            return {
                ...state,
                updateReOrgReportSettingResult : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.updateReOrgReportSetting.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.updateReOrgReportSetting.CLEAR:
            return {
                ...state,
                updateReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
         //deleteReOrgReportSetting
        case tableReportsConstants.deleteReOrgReportSetting.REQUEST: 
            return {
                ...state,
                deleteReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.deleteReOrgReportSetting.SUCCESS:
            return {
                ...state,
                deleteReOrgReportSettingResult : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.deleteReOrgReportSetting.FAILURE:
            return {
                ...state,
                error : action.payload.result,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.deleteReOrgReportSetting.CLEAR:
            return {
                ...state,
                deleteReOrgReportSettingResult : null,
                CUDReOrgReportSettingLoading : false
            }
        case tableReportsConstants.clearCRUDResultForReportGenerator:
            return {
                ...state,
                deleteReOrgReportSettingResult : null,
                createReOrgReportSettingResult : null,
                updateReOrgReportSettingResult : null
            }
        default :
			return state
    }
}