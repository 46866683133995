import { questionConstants } from "./constants";
import "antd/lib/notification/style/index.css";

import Notifications from "../../helpers/Notifications";

const initialState = {
  isQuestionCreated: false,
  isQuestionEdited: false,
  isQuestionDeleted: false,
  isQuestionRestored: false,
  isSending: false,
  currentItem: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case questionConstants.GetAllQuestions.CLEAR:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: false,
        currentItem: {},
      };
    case questionConstants.GetAllQuestions.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.GetAllQuestions.SUCCESS:
      return {
        ...state,
        isSending: false,
        currentItem: action.payload.result,
      };
    case questionConstants.GetAllQuestions.FAILURE:
      //Notifications.errorNotice("Получение вопросов",action.payload.err.data.errors)
      return {
        ...state,
        isSending: false,
      };
    case questionConstants.CreateQuestionName.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.CreateQuestionName.SUCCESS:
      return {
        ...state,
        isQuestionCreated: true,
        isSending: false,
      };
    case questionConstants.CreateQuestionName.FAILURE:
      //Notifications.errorNotice("Создание вопроса",action.payload.err.data.errors)
      return {
        ...state,
        isQuestionCreated: false,
        isSending: false,
      };
    case questionConstants.DeleteQuestionName.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.DeleteQuestionName.SUCCESS:
      return {
        ...state,
        isSending: false,
        isQuestionDeleted: true,
      };
    case questionConstants.DeleteQuestionName.FAILURE:
      //Notifications.errorNotice("Удаление вопроса",action.payload.err.data.errors)
      return {
        ...state,
        isSending: false,
        isQuestionDeleted: false,
      };
    case questionConstants.EditQuestionName.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.EditQuestionName.SUCCESS:
      return {
        ...state,
        isSending: false,
        isQuestionEdited: true,
      };
    case questionConstants.EditQuestionName.FAILURE:
      //Notifications.errorNotice("Редактирование вопроса",action.payload.err.data.errors)
      return {
        ...state,
        isQuestionEdited: false,
        isSending: false,
      };
    case questionConstants.RestoreQuestionName.REQUEST:
      return {
        ...state,
        isQuestionCreated: false,
        isQuestionEdited: false,
        isQuestionDeleted: false,
        isQuestionRestored: false,
        isSending: true,
      };
    case questionConstants.RestoreQuestionName.SUCCESS:
      return {
        ...state,
        isSending: false,
        isQuestionRestored: true,
      };
    case questionConstants.RestoreQuestionName.FAILURE:
      //Notifications.errorNotice("Восстановление вопроса",action.payload.err.data.errors)
      return {
        ...state,
        isSending: false,
        isQuestionRestored: false,
      };
    default:
      return state;
  }
}
