import React, { useState, useEffect, useRef } from 'react';
import { Tooltip, Modal, Row } from 'antd';
import i18n from '../localization';
import { useSelector, useDispatch} from 'react-redux';
import CustomButton from "../../../components/ButtonCustom"
import {
	userActions
}from '../../../store/actions'
import axios from 'axios'

const LogoutTimer = () => {
  const dispatch = useDispatch()

  const { systemEnvironment } = useSelector((state) => state.userReducer)

  const [showModal, setShowModal] = useState(false)
  const secondsToLogout = systemEnvironment.sessionTimeout*60
  const secondsToShowAlert = secondsToLogout/5
  const [timeLeft, setTimeLeft] = useState(secondsToLogout);
  const endTimeRef = useRef(Date.now() + secondsToLogout*1000);
  const requestRef = useRef();

  const updateEndTime = (byUser) => {
    if(byUser){
      dispatch(userActions.refreshUserData())
      setShowModal(false)
    }
    endTimeRef.current = Date.now() + secondsToLogout*1000;
    setTimeLeft(secondsToLogout);
  };
    
  const tick = () => {
    const newTimeLeft = Math.max(0, Math.floor((endTimeRef.current - Date.now()) / 1000));
    setTimeLeft(newTimeLeft);
    if (newTimeLeft === secondsToShowAlert)
      setShowModal(true)
    if (newTimeLeft > 0) {
      requestRef.current = requestAnimationFrame(tick);
    }
    else {
      setShowModal(false)
      if(JSON.parse(window.localStorage.getItem("user"))?.sessionId)
        dispatch(userActions.logout(true)) 
      else
        window.open("/", "_self")
    }
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(tick);
    return () => cancelAnimationFrame(requestRef.current);
    }, [endTimeRef.current]);

  function getTimeInMMSSFormat(seconds){
    return `${ Math.floor(seconds/60).toString().padStart(2, "0")}:${ (seconds%60).toString().padStart(2, "0")}`
    }

  axios.interceptors.request.use((config) => {
    const notUse = config.url.startsWith("http")
    const exceptionUrls = systemEnvironment.routesToIgnoreTimeout.map(
      (value) => { 
        return value.substring(value.indexOf('/', 1), ).toLowerCase();
      }
    )
    if(!exceptionUrls.includes(config.url.toLowerCase()) && !notUse){
      updateEndTime(false)
    }
    return config;
  }, (error) => {
      return Promise.reject(error);
  });


  const isRu = useSelector((state) => state.globalReducer.isRu);

  return (
    <>
      <Tooltip title={i18n.logoutTimer[isRu]} onClick={()=>setShowModal(true)}>
        <div className="logout-timer">{getTimeInMMSSFormat(timeLeft)}</div>
      </Tooltip>
      <Modal
        destroyOnClose
        footer={ null }
        title={ i18n.sessionExtensionTitle[isRu] }
        visible={ showModal }
        width={ 500 }
        onCancel={ () => {
          setShowModal(false)
        } }
      >
        <Row justify="center">
          <CustomButton
            htmlType="submit"
            type="orange"
            onClick={() => updateEndTime(true)}
          >
            { i18n.sessionExtensionBtn[isRu] }
          </CustomButton>
        </Row>
      </Modal>
    </>
  );
};

export default LogoutTimer;