export const externalsConstants = {
	GetApi: {
		REQUEST : 'GET_API_REQUEST',
		SUCCESS : 'GET_API_SUCCESS',
		FAILURE : 'GET_API_FAILURE',
		CLEAR   : 'GET_API_CLEAR'
	},
	RegisterExternalSystem: {
		REQUEST : 'REGISTER_EXTERNAL_SYSTEM_REQUEST',
		SUCCESS : 'REGISTER_EXTERNAL_SYSTEM_SUCCESS',
		FAILURE : 'REGISTER_EXTERNAL_SYSTEM_FAILURE'
	},
	ResetRegistrationData: {
		REQUEST : 'RESET_REGISTRATION_DATA_REQUEST',
		SUCCESS : 'RESET_REGISTRATION_DATA_SUCCESS',
		FAILURE : 'RESET_REGISTRATION_DATA_FAILURE',
		CLEAR   : 'RESET_REGISTRATION_DATA_CLEAR'
	}
}