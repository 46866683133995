export const sessionsConstants = {
  Get: {
    REQUEST: "GET_SESSIONS_REQUEST",
    SUCCESS: "GET_SESSIONS_SUCCESS",
    FAILURE: "GET_SESSIONS_FAILURE",
  },
  Remove: {
    REQUEST: "REMOVE_SESSION_REQUEST",
    SUCCESS: "REMOVE_SESSION_SUCCESS",
    FAILURE: "REMOVE_SESSION_FAILURE",
  }
};
