import { passwordConstants } from "./constants";
import notice from "../../../../components/Notice";
import isRu from "../../../../helpers/isRu";
import i18n from "../../localization";

const initialState = {
  //change
  changeLoading: false,
  isChanged: null,
  changeError: null,
  //restore
  restoreLoading: false,
  isRestored: null,
  restoreError: null,
  //reset
  resetLoading: false,
  isReset: null,
  resetError: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    //change
    case passwordConstants.Change.REQUEST:
      return {
        ...state,
        changeLoading: true,
        isChanged: null,
        changeError: null,
      };
    case passwordConstants.Change.SUCCESS:
      notice("success", i18n.changePasswordSuccessMessage[isRu()]);
      return {
        ...state,
        changeLoading: false,
        isChanged: true,
      };
    case passwordConstants.Change.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        changeLoading: false,
        isChanged: false,
        changeError: action.payload.err.data,
      };
    //restore
    case passwordConstants.Restore.REQUEST:
      return {
        ...state,
        restoreLoading: true,
        isRestored: null,
        restoreError: null,
      };
    case passwordConstants.Restore.SUCCESS:
      notice("success", i18n.changePasswordSuccessMessage[isRu()]);
      return {
        ...state,
        restoreLoading: false,
        isRestored: true,
      };
    case passwordConstants.Restore.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        restoreLoading: false,
        isRestored: false,
        restoreError: action.payload.err.data,
      };
    //reset
    case passwordConstants.Reset.REQUEST:
      return {
        ...state,
        resetLoading: true
      };
    case passwordConstants.Reset.SUCCESS:
      return {
        ...state,
        resetLoading: false,
        isReset: true,
      };
    case passwordConstants.Reset.FAILURE:
      return {
        ...state,
        resetLoading: false,
        isReset: false,
        resetError: action.payload.err.data,
      };
    case passwordConstants.Reset.CLEAR:
      return{
        resetLoading: false,
        isReset: null,
        resetError: null,
      };
    default:
      return state;
  }
}
