export const questionConstants = {
  GetAllQuestions: {
    REQUEST: "GET_QUESTION_REQUEST",
    SUCCESS: "GET_QUESTION_SUCCESS",
    FAILURE: "GET_QUESTION_FAILURE",
    CLEAR: "GET_QUESTION_CLEAR",
  },
  CreateQuestionName: {
    REQUEST: "CREATE_QUESTION_REQUEST",
    SUCCESS: "CREATE_QUESTION_SUCCESS",
    FAILURE: "CREATE_QUESTION_FAILURE",
  },
  DeleteQuestionName: {
    REQUEST: "DELETE_QUESTION_REQUEST",
    SUCCESS: "DELETE_QUESTION_SUCCESS",
    FAILURE: "DELETE_QUESTION_FAILURE",
  },
  EditQuestionName: {
    REQUEST: "EDIT_QUESTION_REQUEST",
    SUCCESS: "EDIT_QUESTION_SUCCESS",
    FAILURE: "EDIT_QUESTION_FAILURE",
  },
  RestoreQuestionName: {
    REQUEST: "RESTORE_QUESTION_REQUEST",
    SUCCESS: "RESTORE_QUESTION_SUCCESS",
    FAILURE: "RESTORE_QUESTION_FAILURE",
  }
};
