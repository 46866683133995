export const superiorReOrgsConstants = {
    AddSuperiorOrg: {
        REQUEST : "ADD_SUPERIOR_ORG_REQUEST",
        SUCCESS : "ADD_SUPERIOR_ORG_SUCCESS",
        FAILURE : "ADD_SUPERIOR_ORG_FAILURE",
        CLEAR   : "ADD_SUPERIOR_ORG_CLEAR"
    },
    RemoveSuperiorOrg: {
        REQUEST : "REMOVE_SUPERIOR_ORG_REQUEST",
        SUCCESS : "REMOVE_SUPERIOR_ORG_SUCCESS",
        FAILURE : "REMOVE_SUPERIOR_ORG_FAILURE",
        CLEAR   : "REMOVE_SUPERIOR_ORG_CLEAR"
    },
    GetPotentialOrgList: {
        REQUEST : "GET_POTENTIAL_ORG_LIST_REQUEST",
        SUCCESS : "GET_POTENTIAL_ORG_LIST_SUCCESS",
        FAILURE : "GET_POTENTIAL_ORG_LIST_FAILURE",
        CLEAR   : "GET_POTENTIAL_ORG_LIST_CLEAR"
    },
    GetSuperiorOrgsPaged: {
		REQUEST : "GET_SUPERIOR_ORGS_PAGED_REQUEST",
		SUCCESS : "GET_SUPERIOR_ORGS_PAGED_SUCCESS",
		FAILURE : "GET_SUPERIOR_ORGS_PAGED_FAILURE",
        CLEAR   : "GET_SUPERIOR_ORGS_PAGED_CLEAR"
	},
    GetSuperiorOrgsList: {
		REQUEST : "GET_SUPERIOR_ORGS_LIST_REQUEST",
		SUCCESS : "GET_SUPERIOR_ORGS_LIST_SUCCESS",
		FAILURE : "GET_SUPERIOR_ORGS_LIST_FAILURE",
        CLEAR   : "GET_SUPERIOR_ORGS_LIST_CLEAR"
	},
    ClearSuperiorOrgsValues : "CLEAR_SUPERIOR_ORGS_VALUES"

}