import {
    reOrgReceptionPersonConstants
  } from './constants'
  
  const initialState =
  {
    isSending: false,
    reOrgReceptionPersonsList: null,
    reOrgReceptionPersonsListLoading: false,
    reOrgReceptionPersonsPaged: null,
    reOrgReceptionPersonsPagedLoading: false,
    createReOrgReceptionPersonResult: null,
    restoreReOrgReceptionPersonResult: null,
    updateReOrgReceptionPersonResult: null,
    deleteReOrgReceptionPersonResult: null,
    error: null
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      //getReOrgReceptionPersonsPaged
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsPaged.REQUEST:
        return {
          ...state,
          reOrgReceptionPersonsPagedLoading: true
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsPaged.SUCCESS:
        return {
          ...state,
          reOrgReceptionPersonsPagedLoading: false,
          reOrgReceptionPersonsPaged: action.payload.result
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsPaged.FAILURE:
        return {
          ...state,
          reOrgReceptionPersonsPagedLoading: false,
          error: action.payload.result
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsPaged.CLEAR:
        return {
          ...state,
          reOrgReceptionPersonsPagedLoading: false,
          reOrgReceptionPersonsPaged: null,
        }
      //getReOrgReceptionPersonsList
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsList.REQUEST:
        return {
          ...state,
          reOrgReceptionPersonsListLoading: true
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsList.SUCCESS:
        return {
          ...state,
          reOrgReceptionPersonsListLoading: false,
          reOrgReceptionPersonsList: action.payload.result
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsList.FAILURE:
        return {
          ...state,
          reOrgReceptionPersonsListLoading: false,
          error: action.payload.result
        }
      case reOrgReceptionPersonConstants.getReOrgReceptionPersonsList.CLEAR:
        return {
          ...state,
          reOrgReceptionPersonsListLoading: false,
          reOrgReceptionPersonsList: null,
        }
      //reOrgReceptionPersonCRUD
      case reOrgReceptionPersonConstants.createReOrgReceptionPerson.REQUEST:
      case reOrgReceptionPersonConstants.restoreReOrgReceptionPerson.REQUEST:
      case reOrgReceptionPersonConstants.updateReOrgReceptionPerson.REQUEST:
      case reOrgReceptionPersonConstants.deleteReOrgReceptionPerson.REQUEST:
        return {
          ...state,
          isSending: true
        }
      case reOrgReceptionPersonConstants.createReOrgReceptionPerson.SUCCESS:
        return {
            ...state,
            isSending: false,
            createReOrgReceptionPersonResult: action.payload.result
        }
      case reOrgReceptionPersonConstants.restoreReOrgReceptionPerson.SUCCESS:
        return {
            ...state,
            isSending: false,
            restoreReOrgReceptionPersonResult: action.payload.result
        }
      case reOrgReceptionPersonConstants.updateReOrgReceptionPerson.SUCCESS:
        return {
            ...state,
            isSending: false,
            updateReOrgReceptionPersonResult: action.payload.result
        }
      case reOrgReceptionPersonConstants.deleteReOrgReceptionPerson.SUCCESS:
        return {
            ...state,
            isSending: false,
            deleteReOrgReceptionPersonResult: action.payload.result
        }
      case reOrgReceptionPersonConstants.createReOrgReceptionPerson.FAILURE:
      case reOrgReceptionPersonConstants.restoreReOrgReceptionPerson.FAILURE:
      case reOrgReceptionPersonConstants.updateReOrgReceptionPerson.FAILURE:
      case reOrgReceptionPersonConstants.deleteReOrgReceptionPerson.FAILURE:
        return {
          ...state,
          isSending: false,
          error: action.payload.result
        }
      case reOrgReceptionPersonConstants.clearReOrgReceptionPersonCRUDvalues: 
        return {
          ...state,
          isSending: false,
          createReOrgReceptionPersonResult: null,
          restoreReOrgReceptionPersonResult: null,
          updateReOrgReceptionPersonResult: null,
          deleteReOrgReceptionPersonResult: null,
        }  
      default:
        return state
    }
  }