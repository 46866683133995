/* eslint-disable sort-keys */
import {
	AisMvManageConstant
}from "./constants"
import i18n from "../localization"
import isRu
from "../../../../helpers/isRu"
const initialState = {
 	changeIsFailure          : null,
	changed                  : null,
	error                    : null,
	isSending                : false,
	subscriberStatus         : null,
	aisMvHistory             : null,
	aisMvMessageHistory      : {},
	isDownloading            : false,
	fileDownload             : null,
	bodyAttachment           : null,
	failure                  : null,
	isModalSending           : null,
	appealMessagesFiles      : null,
	subscriberList           : null,
	getSubscriberListSending : false
}

export default function (state = initialState, action){
	switch (action.type){
		case AisMvManageConstant.ChangeSubscribeAisMv.REQUEST:
			return {
				...state,
				isSending       : true,
				changeIsFailure : null
			}
		case AisMvManageConstant.ChangeSubscribeAisMv.FAILURE:
			return {
				...state,
				isSending       : false,
				changeIsFailure : action.payload
			}
		case AisMvManageConstant.ChangeSubscribeAisMv.SUCCESS:
			return {
				...state,
				isSending : false,
				changed   : action.payload.result ? i18n.sucsessNotice[isRu()] : null
			}
		case AisMvManageConstant.CheckSubscribeAisMv.REQUEST:
			return {
				...state,
				isSending       : true,
				changeIsFailure : null,
				changed         : null
			}
		case AisMvManageConstant.CheckSubscribeAisMv.FAILURE:
			return {
				...state,
				isSending        : false,
				changeIsFailure  : action.payload,
				subscriberStatus : null
			}
		case AisMvManageConstant.CheckSubscribeAisMv.SUCCESS:
			return {
				...state,
				isSending        : false,
				subscriberStatus : action.payload.result
			}
		case AisMvManageConstant.DownloadAisMVFile.REQUEST:
			return {
				...state,
				isDownloading : true,
				fileDownload  : null
			}
		case AisMvManageConstant.DownloadAisMVFile.FAILURE:
			return {
				...state,
				isDownloading : false,
				failure       : action.payload
			}
		case AisMvManageConstant.DownloadAisMVFile.SUCCESS:
			return {
				...state,
				isDownloading : false,
				fileDownload  : action.payload.result
			}
		case AisMvManageConstant.DownloadAisMVFile.СLEAR :
			return {
				...state,
				fileDownload  : null,
				isDownloading : false
			}
		case AisMvManageConstant.GetMessageBody.REQUEST:
			return {
				...state,
				isModalSending : true,
				failure        : null
			}
		case AisMvManageConstant.GetMessageBody.FAILURE:
			return {
				...state,
				failure        : action.payload,
				bodyAttachment : null,
				isModalSending : false
			}
		case AisMvManageConstant.GetMessageBody.SUCCESS:
			return {
				...state,
				bodyAttachment : action.payload.result,
				isModalSending : false
			}
		case AisMvManageConstant.GetMessages.REQUEST:
			return {
				...state,
				isSending       : true,
				changeIsFailure : null
			}
		case AisMvManageConstant.GetMessages.FAILURE:
			return {
				...state,
				changeIsFailure : action.payload,
				aisMvHistory    : null,
				isSending       : false
			}
		case AisMvManageConstant.GetMessages.SUCCESS:
			return {
				...state,
				isSending    : false,
				aisMvHistory : action.payload.result
			}
		case AisMvManageConstant.GetAisMVMessageHistory.REQUEST:
			return {
				...state,
				isSending       : true,
				changeIsFailure : null
			}
		case AisMvManageConstant.GetAisMVMessageHistory.FAILURE:
			return {
				...state,
				changeIsFailure     : action.payload,
				aisMvMessageHistory : null,
				isSending           : false
			}
		case AisMvManageConstant.GetAisMVMessageHistory.SUCCESS:
			return { ...state,
				aisMvMessageHistory: { ...action.payload.result.items.reduce((acc, elem) => {
					if (acc[elem.reOrgMessageId])
						acc[elem.reOrgMessageId].push(elem)
					else
						acc[elem.reOrgMessageId] = [elem]
					return acc
				}, state.aisMvMessageHistory) },
				isSending: false }

		case AisMvManageConstant.GetAppealAisMvMessageFiles.REQUEST:{
			return {
				...state,
				isSending           : true,
				appealMessagesFiles : null
			}
		}
		case AisMvManageConstant.GetAppealAisMvMessageFiles.FAILURE:{
			return {
				...state,
				isSending           : false,
				appealMessagesFiles : null
			}
		}
		case AisMvManageConstant.GetAppealAisMvMessageFiles.SUCCESS:
			return {
				...state,
				isSending           : false,
				appealMessagesFiles : action.payload.result

			}
		case AisMvManageConstant.GetSubscribersBySearch.REQUEST:
			return {
				...state,
				getSubscriberListSending: true
			}
		case AisMvManageConstant.GetSubscribersBySearch.FAILURE:
			return {
				...state,
				error                    : action.payload,
				getSubscriberListSending : false
			}
		case AisMvManageConstant.GetSubscribersBySearch.SUCCESS:
			return {
				...state,
				getSubscriberListSending : false,
				subscriberList           : action.payload.result
			}
		default:
			return state
	}
}

