export const reOrgReceptionPersonConstants = {
    createReOrgReceptionPerson : {
      REQUEST: 'CREATE_REORG_RECEPTION_PERSON_REQUEST',
      SUCCESS: 'CREATE_REORG_RECEPTION_PERSON_SUCCESS',
      FAILURE: 'CREATE_REORG_RECEPTION_PERSON_FAILURE',
    },
    deleteReOrgReceptionPerson : {
      REQUEST: 'DELETE_REORG_RECEPTION_PERSON_REQUEST',
      SUCCESS: 'DELETE_REORG_RECEPTION_PERSON_SUCCESS',
      FAILURE: 'DELETE_REORG_RECEPTION_PERSON_FAILURE',
    },
    getReOrgReceptionPersonsPaged : {
      REQUEST: 'GET_REORG_RECEPTION_PERSONS_PAGED_REQUEST',
      SUCCESS: 'GET_REORG_RECEPTION_PERSONS_PAGED_SUCCESS',
      FAILURE: 'GET_REORG_RECEPTION_PERSONS_PAGED_FAILURE',
      CLEAR: 'GET_REORG_RECEPTION_PERSONS_PAGED_CLEAR'
    },
    getReOrgReceptionPersonsList : {
      REQUEST: 'GET_REORG_RECEPTION_PERSONS_LIST_REQUEST',
      SUCCESS: 'GET_REORG_RECEPTION_PERSONS_LIST_SUCCESS',
      FAILURE: 'GET_REORG_RECEPTION_PERSONS_LIST_FAILURE',
      CLEAR: 'GET_REORG_RECEPTION_PERSONS_LIST_CLEAR'
    },
    restoreReOrgReceptionPerson : {
      REQUEST: 'RESTORE_REORG_RECEPTION_PERSON_REQUEST',
      SUCCESS: 'RESTORE_REORG_RECEPTION_PERSON_SUCCESS',
      FAILURE: 'RESTORE_REORG_RECEPTION_PERSON_FAILURE',
    },
    updateReOrgReceptionPerson : {
      REQUEST: 'UPDATE_REORG_RECEPTION_PERSON_REQUEST',
      SUCCESS: 'UPDATE_REORG_RECEPTION_PERSON_SUCCESS',
      FAILURE: 'UPDATE_REORG_RECEPTION_PERSON_FAILURE',
    },
    clearReOrgReceptionPersonCRUDvalues : 'CLEAR_REORG_RECEPTION_PERSON_CRUD_VALUES'
  }